import React from 'react';
import { NavigationProvider } from './Navigation/context';
import { NavigationWithModal } from './Navigation';

import { ThemeProvider } from './provider';
import theme from './themes';

export const Navigation = props => (
  <NavigationProvider>
    <NavigationWithModal {...props} />
  </NavigationProvider>
);

export const NavigationWithProvider = props => {
  return (
    <ThemeProvider theme={theme}>
      <Navigation {...props} />
    </ThemeProvider>
  );
};
