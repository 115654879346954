import React from 'react';
import { Box } from '../../provider';

const NameLogo = ({
  sx,
  rotate = 0,
  color = '#FFFFFF',
  logoText = 'My Site',
  fontFamily = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
}) => {
  return (
    <Box
      as="svg"
      focusable="false"
      viewBox="0 0 800 100"
      ariaHidden="true"
      x="0px"
      y="0px"
      sx={{
        ...sx,
      }}
    >
      <Box
        as="text"
        fill={color}
        transform="matrix(1 0 0 1 0 80)"
        fontFamily={`${fontFamily}`}
        fontSize="80px"
      >
        {logoText}
      </Box>
    </Box>
  );
};

export default NameLogo;
