import React from 'react';
import { GatsbyLinkButton } from '@talves/gatsby-theme-components-tui';
// import { MDXProvider } from '@mdx-js/react';
// import { MDXRenderer } from 'gatsby-plugin-mdx';
import { Layout } from './layout';
import { Box, useThemeUI } from '../../provider';

export const LayoutMDX = ({
  children,
  returnPath,
  data = {},
  _frontmatter: frontmatter = {},
  ...props
}) => {
  const { theme } = useThemeUI();
  return (
    <Layout
      backgroundStyles={props.backgroundStyles}
      header={frontmatter.title}
      frontmatter={frontmatter}
    >
      {returnPath && <GatsbyLinkButton to={returnPath}>Posts</GatsbyLinkButton>}
      <Box as="h1" sx={{ mt: '20px' }}>
        {data.title}
      </Box>
      <Box
        sx={{
          mt: [10, 15, 20],
          fontSize: [1, 2, 3],
          '& >p': {
            pl: '20px',
            pb: ['15px', '20px', '30px'],
            '& a': {
              appearance: 'none',
              border: 0,
              borderRadius: ['6px', '8px', '10px'],
              color: 'text',
              fontWeight: 'bold',
              padding: '0.1em',
              cursor: 'pointer',
              transition: 'background-size 0.2s linear',
              textDecoration: 'none',
              backgroundImage: `linear-gradient(${theme.colors.background}, ${theme.colors.accent}), linear-gradient(${theme.colors.background}, ${theme.colors.accent}), linear-gradient(${theme.colors.accent},${theme.colors.background})`,
              backgroundSize: '50% 0.3em, 50% 0.3em, 0 0',
              backgroundPosition: '0 100%, 100% 100%, 50% 0',
              backgroundRepeat: 'no-repeat',
              '&:hover': {
                backgroundSize: '0 2px, 0 2px, 100% 0.75em',
              },
            },
          },
          ul: { pl: '40px', pb: ['15px', '20px', '30px'] },
        }}
      >
        {children}
      </Box>
      {false && <Box as="pre">{JSON.stringify(data, null, 2)}</Box>}
    </Layout>
  );
};
