import React from 'react';
import Link from '../GatsbyLink';
import { Box } from '../provider';

import Modal from '../modal';
import withData from './data';

/* see https://codesandbox.io/s/yvkol51m81 */

const NavItem = ({ children, ...props }) => (
  <Link
    {...props}
    sx={{
      color: 'secondary',
      display: 'block',
      textTransform: 'uppercase',
      textDecoration: 'none',
      fontSize: '1rem',
      fontWeight: 700,
      padding: '10px 20px',
      '&:hover': {
        backgroundColor: 'secondary',
        color: 'white',
      },
    }}
  >
    <Box
      sx={{
        verticalAlign: 'middle',
      }}
    >
      {children}
    </Box>
  </Link>
);

const NavModalMenu = ({ data, ...props }) => {
  return (
    <Modal {...props}>
      {data &&
        data.items
          .filter(item => !item.hidden)
          .map((item, i) => {
            return (
              <NavItem key={i} to={`${item.path || '/'}`}>
                {item.label}
              </NavItem>
            );
          })}
    </Modal>
  );
};

export const NavModalMenuWithData = withData(props => (
  <NavModalMenu {...props} />
));

export default NavModalMenu;
