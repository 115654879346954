import React from 'react';
import { NavigationWithData } from './data';
import { NavModalMenuWithData } from './nav-modal-menu';
import { useNavigationDispatch, useNavigationState } from './context';
import useMediaBreakpoints from '../hooks/use-media-breakpoints';

const NavigationLayout = ({ onMenuOpen, onMenuClose, onMenuButtonShow }) => {
  const navDispatch = useNavigationDispatch();
  const showMenuCompressed = useMediaBreakpoints(
    [`(min-width:40em)`],
    [false],
    true,
  );

  React.useEffect(() => {
    if (typeof navDispatch === 'function')
      navDispatch({
        type: showMenuCompressed ? 'MENU_COMPRESSED' : 'MENU_NOT_COMPRESSED',
      });
  }, [showMenuCompressed, navDispatch]);

  return (
    <NavigationWithData
      onMenuButtonShow={onMenuButtonShow}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
    />
  );
};

export const NavigationWithModal = () => {
  const navDispatch = useNavigationDispatch();
  const { openMenu } = useNavigationState();
  const [showModal, setShowModal] = React.useState(openMenu);

  React.useEffect(() => {
    if (typeof navDispatch === 'function')
      navDispatch({ type: showModal ? 'OPEN_MENU' : 'CLOSE_MENU' });
  }, [showModal, navDispatch]);

  return (
    <>
      <NavigationLayout
        onMenuButtonShow={() => {}}
        onMenuOpen={() => {
          setShowModal(true);
        }}
        onMenuClose={() => {
          setShowModal(false);
        }}
      />
      {openMenu && <NavModalMenuWithData onClose={() => setShowModal(false)} />}
    </>
  );
};

export default NavigationLayout;
