import React from 'react';
import { useLockBodyScroll } from 'react-use';
import { Box, Flex } from './provider';

export default function Modal({ children, onClose, ...props }) {
  const [locked, setLocked] = React.useState(true);
  // Call hook to lock body scroll
  useLockBodyScroll(locked);

  const handleClick = event => {
    setLocked(false);
    if (typeof onClose === 'function') onClose(event);
  };

  return (
    <Flex
      onClick={handleClick}
      {...props}
      sx={{
        zIndex: 10000,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.65)',
        padding: '1em',
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
      }}
    >
      <Box
        onClick={handleClick}
        sx={{
          backgroundColor: 'white',
          borderRadius: '3px',
          padding: '2rem 3rem',
          textAlign: 'center',
        }}
      >
        {children}
      </Box>
    </Flex>
  );
}
