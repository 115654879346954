import React from 'react';
// Context of Navigation!
const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MENU':
      return {
        ...state,
        openMenu: true,
      };
    case 'CLOSE_MENU':
      return {
        ...state,
        openMenu: false,
      };
    case 'MENU_COMPRESSED':
      return {
        ...state,
        showMenuCompressed: true,
      };
    case 'MENU_NOT_COMPRESSED':
      return {
        openMenu: false,
        showMenuCompressed: false,
      };
    default:
      return state;
  }
};
const initialState = {
  showMenuCompressed: false,
  openMenu: false,
};

const NavigationContext = React.createContext(initialState);
const NavigationDispatchContext = React.createContext();

function NavigationProvider({ children }) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <NavigationContext.Provider value={state}>
      <NavigationDispatchContext.Provider value={dispatch}>
        {children}
      </NavigationDispatchContext.Provider>
    </NavigationContext.Provider>
  );
}

function useNavigationState() {
  const context = React.useContext(NavigationContext);
  if (context === undefined) {
    throw new Error(
      'useNavigationState must be used within a NavigationProvider',
    );
  }
  return context;
}
function useNavigationDispatch() {
  const context = React.useContext(NavigationDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useNavigationDispatch must be used within a NavigationProvider',
    );
  }
  return context;
}

export { NavigationProvider, useNavigationState, useNavigationDispatch };
