import React from 'react';
import { Link } from 'gatsby';
import { Box } from '../provider';

export default ({ children, ...props }) => (
  <Box
    as={Link}
    {...props}
    sx={{
      color: 'inherit',
      '&.active': {
        color: 'muted',
      },
    }}
  >
    {children}
  </Box>
);
