import React from 'react';
import { Box } from '../provider';

const NavButton = ({ onMenuOpen, onMenuClose, openMenu, color }) => {
  const handleMenuClick = React.useCallback(
    open => {
      if (open) {
        if (typeof onMenuOpen === 'function') onMenuOpen();
      } else {
        if (typeof onMenuClose === 'function') onMenuClose();
      }
    },
    [onMenuOpen, onMenuClose],
  );

  return (
    <Box
      onClick={event => {
        event.preventDefault();
        handleMenuClick(!openMenu);
      }}
      sx={{
        display: 'inline-block',
        // textDecoration: 'none',
        minWidth: 32,
        '&:hover': {
          borderRadius: '50%',
          backgroundColor: 'background',
          '& svg': {
            stroke: `${color}`,
          },
        },
      }}
    >
      <Box
        as="svg"
        viewBox="0 0 60 60"
        sx={{
          verticalAlign: 'middle',
          pointerEvents: 'none',
          stroke: `${color || 'white'}`,
          fill: 'transparent',
          strokeLinecap: 'round',
          strokeWidth: 5,
        }}
      >
        <g>
          <path
            d={
              openMenu
                ? 'M 15,15 L 45,45 M 45,15 L 15,45' // X - close
                : 'M 15,15 L 45,15 M 15,30 L 45,30 M 15,45 L 45,45' // Hamburger - open
            }
          />
        </g>
      </Box>
    </Box>
  );
};

export default NavButton;

/*
eslint
  no-unused-vars: "off"
*/
