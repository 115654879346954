import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Navigation from './base';

// eslint-disable-next-line
export const _query = graphql`
  fragment NavigationData on NavigationMenu {
    items {
      hidden
      label
      path
      type
    }
  }
`;

const withData = Component => props => {
  const data = useStaticQuery(graphql`
    query {
      navigation: navigationMenu {
        ...NavigationData
      }
    }
  `);

  return <Component data={data.navigation} {...props} />;
};

export default withData;

export const NavigationWithData = withData(Navigation);
