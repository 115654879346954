import React from 'react';
import { OutsideLink } from '@talves/gatsby-theme-components-tui';

import { Box, useThemeUI } from '../../provider';
import TwitterIcon from '../icons/social/twitter-icon';
import GitHubIcon from '../icons/social/github-icon';

const FooterSection = props => {
  const { theme } = useThemeUI();
  return (
    <>
      <Box
        sx={{
          width: '100%',
          minWidth: 0,
          maxWidth: 1024,
          mx: 'auto',
          p: 4,
        }}
      >
        <Box
          sx={{
            color: theme.colors.background,
            height: '100%',
            display: 'block',
            '& > *': {
              verticalAlign: 'bottom',
            },
          }}
        >
          <Box sx={{ height: '80%' }} />
          <OutsideLink
            to={`https://twitter.com/3_Alves`}
            sx={{
              p: '10px',
              display: 'inline-block',
            }}
          >
            <Box
              sx={{
                height: 30,
                width: 30,
              }}
            >
              <TwitterIcon fill={theme.colors.background} />
            </Box>
          </OutsideLink>
          <OutsideLink
            to={`https://github.com/talves`}
            sx={{
              p: '10px',
              display: 'inline-block',
            }}
          >
            <Box
              sx={{
                height: 30,
                width: 30,
              }}
            >
              <GitHubIcon fill={theme.colors.background} />
            </Box>
          </OutsideLink>
          <Box
            sx={{
              height: '50px',
              p: '10px',
              fontSize: 18,
              display: 'inline-block',
            }}
          >
            © Tony Alves{' '}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FooterSection;
