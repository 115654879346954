import React from 'react';
import { Box, Flex, useThemeUI } from '../provider';
import Link from '../GatsbyLink';
import NavButton from './nav-button';
import { useNavigationState } from './context';
import { NameLogo } from './name-logo';

const Logo = ({ href, src, alt, color }) => (
  <Link to={href || '/'} sx={{ display: 'inline-block' }}>
    <NameLogo logoText={alt} color={color} sx={{ height: [30, 40, 50] }} />
  </Link>
);

const NavItem = ({ children, ...props }) => {
  const {
    theme: { colors: { navtext = 'white' } = {} },
  } = useThemeUI();
  return (
    <Link {...props}>
      <Box
        sx={{
          cursor: 'pointer',
          color: `${navtext}`,
          display: 'inline-block',
          textTransform: 'uppercase',
          fontSize: ['0.75rem', '0.9rem', '1rem'],
          fontWeight: [400, 500, 600],
          padding: '10px 20px',
          '&:hover': {
            color: 'primary',
          },
        }}
      >
        {children}
      </Box>
    </Link>
  );
};

const NavigationWrapper = ({ children, ...props }) => (
  <Flex
    as="nav"
    {...props}
    sx={{
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      paddingLeft: 30,
      paddingRight: 10,
      variant: 'styles.Nav',
    }}
  >
    {children}
  </Flex>
);

const Navigation = ({ data, onMenuOpen, onMenuClose }) => {
  const navState = useNavigationState();
  const {
    theme: { colors: { navtext = 'white' } = {} },
  } = useThemeUI();

  return (
    <NavigationWrapper>
      <Logo color={navtext} />
      <Flex>
        {navState && navState.showMenuCompressed && (
          <NavButton
            color={navtext}
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuClose}
            openMenu={navState.openMenu}
          />
        )}
        {navState &&
          !navState.showMenuCompressed &&
          data &&
          data.items
            .filter(item => !item.hidden)
            .map((item, i) => {
              const itemComponent = (
                <NavItem key={i} to={`${item.path || '/'}`}>
                  {item.label}
                </NavItem>
              );
              return itemComponent;
            })}
      </Flex>
    </NavigationWrapper>
  );
};

export default Navigation;
